// src/components/animations/OpenPageAnim.jsx

import * as React from 'react';
import { gsap } from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";


const OpenPageAnim = () => {
  const OpenPageAnimRef = React.useRef(null);
  const [isAnimating, setIsAnimating] = React.useState(true);

  React.useEffect(() => {

    const tl = gsap.timeline({
      onComplete: () => {
        gsap.set(OpenPageAnimRef.current, { autoAlpha: 0 });
        setIsAnimating(false)
      },
    });

    tl.to(OpenPageAnimRef.current, { x: '-100%', duration: 0.6 });

    return () => {
      gsap.killTweensOf(OpenPageAnimRef);
      ScrollTrigger.refresh();
    }
  }, []);

  // Do not render the div if it is not animating to avoid blocking the page
  if (!isAnimating) {
    return null;
  }

  return (
      <div ref={OpenPageAnimRef} className={'fixed w-screen h-screen bg-black z-50 animate-hide-delay'}/>
  );
};

export default OpenPageAnim;