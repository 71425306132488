// src/components/Layout.jsx

import * as React from 'react';
import { useLocation } from '@reach/router';
import { gsap } from 'gsap';

import UpdateHelper from './common/UpdateHelper';
import ScrollHelper from './common/ScrollHelper';
import OpenPageAnim from './animations/OpenPageAnim';

import Header from './common/Header';
import Footer from './common/Footer';
import {ScrollTrigger} from "gsap/ScrollTrigger";


export default function Layout({children}) {

  const location = useLocation();

  // Main contents entrance animation
  const MainContentRef = React.useRef(null);
  React.useEffect(() => {

    ScrollTrigger.refresh();

    gsap.from(MainContentRef.current, {
      delay: 0.5,
      duration: 0.3,
      opacity: 0,
    });

    return () => {
      gsap.killTweensOf(MainContentRef);
      ScrollTrigger.refresh();
    }

  }, [location.pathname]);

  // Gatsby-plugin-offline worker update notice logic
  const [isUpdateAvailable, setIsUpdateAvailable] = React.useState(false);
  React.useEffect(() => {
    // Register service worker update ready listener
    const onSWUpdateReady = () => setIsUpdateAvailable(true);
    window.addEventListener('serviceWorkerUpdateReady', onSWUpdateReady);

    return () => {
      window.removeEventListener('serviceWorkerUpdateReady', onSWUpdateReady);
    };
  }, []);

  return (
      <>

        {/* Header component */}
        <Header/>

        {/* Page opening animation */}
        <OpenPageAnim/>

        {/* Gatsby-plugin-offline Worker Update Notice */}
        <UpdateHelper isOpen={isUpdateAvailable}/>

        {/* Scroll tooltip and go-up button */}
        <ScrollHelper/>


        {/* Main page content (children) */}
        <main ref={MainContentRef} className={'scroll-smooth'}>
          {children}
        </main>


        {/* Conditionally render the Footer */}
        {!(location.pathname === '/contact/' || location.pathname.match(/^\/case\//)) && <Footer/>}

      </>
  )
}